
.case-list {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 3.33%;
  right: 3.33%;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.case-list > svg {
    position: absolute;
    width: 0;
    height: 0;
}
.case-list__moving-box {

    -webkit-clip-path: url(#caselist-moving-box-clip);

            clip-path: url(#caselist-moving-box-clip);

    opacity: 0;

    position: absolute;
    width: calc(100% / 4);
    height: 100%;
    top: 0;
    left: calc(100% / 4 * -1);
    
    overflow: hidden;
}
.case-list__moving-box-background {
      position: absolute;
      width: 100%;
      height: 71.71%;
      top: 0;
      left: 0;

      z-index: 1;
}
.case-list__moving-box-background--1 {
        background: url(/case-bg-1.jpg?80cb1f985e9e33afa517b3a4151a1160) no-repeat center / cover;
}
.case-list__moving-box-background--2 {
        background: url(/case-bg-2.jpg?f18ae0f3967c21bde09b469ca1ebc3d1) no-repeat center / cover;
        z-index: -1;
}
.case-list__moving-box-background--3 {
        background: url(/case-bg-3.jpg?a4b2b850f328448d0d649b86f9f12d20) no-repeat center / cover;
        z-index: -1;
}
.case-list__moving-box-background--4 {
        background: url(/case-bg-4.jpg?0ea6a33a3920b3daceed726b3544f39c) no-repeat center / cover;
        z-index: -1;
}
.case-list__item {
    width: calc(100% / 4);
    height: 100%;
    position: relative;
}
.case-list__item-content {
      position: absolute;
      left: 14.285%;
      top: 26.38%;
      width: 71.42%;
      bottom: 33.24%;
}
.case-list__item-icon {
      width: calc(60 * 100vw / 1920);
      height: calc(60 * 100vw / 1920);
}
.case-list__item-title {
      font-weight: bold;
      color: #283032;
      color: var(--primary-black);
      font-size: calc(30 * 100vw / 1920);
      margin: 12% auto 14.66%;
      line-height: calc(42 * 100vw / 1920);

      height: 37.5%;
}
.case-list__item-description {
      color: #7b7b7b;
      font-size: calc(14 * 100vw / 1920);
      
      line-height: calc(28 * 100vw / 1920);

      position: relative;
}
.case-list__item-description-illus {
        width: calc(6 * 100vw / 1920);
        height: calc(13 * 100vw / 1920);
        position: absolute;
        left: 0;
        bottom: 105%;

        -webkit-transform: translateX(100%) rotate(90deg);

                transform: translateX(100%) rotate(90deg);
}
.case-list__item-description-illus svg {
          position: absolute;
          width: 100%;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50% ,-50%);
                  transform: translate(-50% ,-50%);
}
.case-list__item-order {
      position: absolute;
      /* bottom: 0; */
      /* bottom: -17.5%; */
      bottom: -28.5%;
      width: 100%;
      left: 0;
      font-family: 'Oxanium Regular';
}
.case-list__item-order-numerator {
        
        font-size: calc(36 * 100vw / 1920);
        color: #000;
}
.case-list__item-order-denominator {
        font-weight: bold;
        font-size: calc(18 * 100vw / 1920);
        color: #7b7b7b;
}
.case-list__item-order-slash {
        display: inline-block;
        width: calc(8 * 100vw / 1920);
        height: calc(8 * 100vw / 1920);
        position: relative;
}
.case-list__item-order-slash::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%) rotate(-45deg);
                  transform: translate(-50%, -50%) rotate(-45deg);
          border-radius: 4px;
          width: 100%;
          height: 2px;
          background: #e7e7e7;
}
.attachment {
  background: #E72420;
  background: var(--primary-red);
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 28.3%;
  bottom: 0;
}
.attachment__header{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /* width: 100%; */
    /* margin: 16.5% 0; */
    width: 90%;
    margin: 12% auto 17.5%;
}
.attachment__number {
    width: 50%;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.attachment__number-text {
      color: #fff;
      font-size: calc(36 * 100vw / 1920);
      font-weight: bold;
      font-family: 'Oxanium Regular';
}
.attachment__number-label {
      
      font-size: calc(14 * 100vw / 1920);
      color: #fff;
}
.attachment__link {
    border: 2px solid #fff;
    border-radius: 4px;
    padding: 5% 8%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;

    /* width: 75%; */
    width: 62.5%;
    height: 22.5%;

    margin-left: auto;
    margin-right: auto;


    position: relative;
    overflow: hidden;
}
.attachment__link::before {
      content: '';
      position: absolute;

      width: calc(367.5 * 100vw / 1920);
      height: calc(183.75 * 100vw / 1920);
      
      border-radius: 50%;
      right: 150%;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      z-index: -1;
      -webkit-transition: 2000ms 0ms all cubic-bezier(0.19, 1, 0.22, 1);
      transition: 2000ms 0ms all cubic-bezier(0.19, 1, 0.22, 1);

      background:rgba(255,255,255,1);
      opacity:0.15;
}
.attachment__link:hover::before {
      -webkit-transform: translateY(-50%) translateX(120%);
              transform: translateY(-50%) translateX(120%);
}
.attachment__link-text {
      font-size: calc(16 * 100vw / 1920);
      color: #fff;
      margin-right: auto;
}
.attachment__link:hover .attachment__link-icon {
      -webkit-transform: translateX(25%);
              transform: translateX(25%);
}
.attachment__link-icon {
      -webkit-transition: 2000ms 0ms transform cubic-bezier(0.19, 1, 0.22, 1);
      transition: 2000ms 0ms transform cubic-bezier(0.19, 1, 0.22, 1);
      width: calc(29 * 100vw / 1920);
      height: calc(12 * 100vw / 1920);

      display: -webkit-box;

      display: -ms-flexbox;

      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
}
.attachment__link-icon-frag {
        width: 17.5%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
.attachment__link-icon-frag svg {
          width: 100%;
}
use.attachment__link-icon-frag-unit {
  fill: #fff;
}
use.case-list__item-description-illus-unit {
  fill: #e7e7e7;
}




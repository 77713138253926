html, body {
  height: 100%;
}

#logo-frag-1 path,
#logo-frag-2 path,
#logo-frag-3 path,
#back-arrow path {
  fill: inherit;
}

use.full-logo__unit {
  fill: #fff;
}

.preload-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #E72420;
  z-index: 99999;
}

.full-logo {
  position: absolute;
  width: 25vw;
  height: calc(25vw * 86.26 / 258.8);

  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.full-logo-piece {
  position: absolute;
  height: 100%;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

}

.full-logo > div {
  visibility: hidden;
}

.full-logo-piece > svg {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.full-logo__middle {
  width: calc(25vw * 40.99 / 258.8);
}

.full-logo__middle-left {
  width: calc(25vw * 40.41 / 258.8);
  /* transform: translateX(calc((calc(25vw * 40.99 / 258.8) + calc(25vw * 15.9 / 258.8)) * -1)); */
  -webkit-transform: translateX(-140%);
          transform: translateX(-140%);
}

.full-logo__left {
  width: calc(25vw * 36.69 / 258.8);
  /* transform: translateX(calc((calc(25vw * 40.99 / 258.8) + calc(25vw * 40.41 / 258.8) + calc(25vw * 15.9 / 258.8) * 2) * -1)); */
  -webkit-transform: translateX(-308.5%);
          transform: translateX(-308.5%);
}

.full-logo__middle-right {
  width: calc(25vw * 40.41 / 258.8);
  /* transform: translateX(calc((calc(25vw * 40.99 / 258.8) + calc(25vw * 15.9 / 258.8)) * 1)) scaleX(-1); */
  -webkit-transform: translateX(140%) scaleX(-1);
          transform: translateX(140%) scaleX(-1);
}

.full-logo__right {
  width: calc(25vw * 36.69 / 258.8);
  /* transform: translateX(calc((calc(25vw * 40.99 / 258.8) + calc(25vw * 40.41 / 258.8) + calc(25vw * 15.9 / 258.8) * 2) * 1)) scaleX(-1); */
  -webkit-transform: translateX(308.5%) scaleX(-1);
          transform: translateX(308.5%) scaleX(-1);
}

.brand-logo-text {
  width: 100%;
  position: absolute;
  top: 127.5%;
  left: 0;
  height: 50%;
}

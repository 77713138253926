
.contact {
  position: absolute;
  width: 100%;
  height: 100%;
}
.contact__address {
    width: 25.29%;
    height: 100%;

    padding: calc(30 * 100vw / 1920) calc(36 * 100vw / 1920);
}
.contact__address * {
      line-height: 100%;
}
.contact__address-item {
      width: calc(250 * 100vw / 1920);
      font-size: calc(16 * 100vw / 1920);
      font-weight: bold;
}
.contact__address-item:not(:first-of-type) {
        margin-top: calc(48 * 100vw / 1920);
}
.contact__address-item--social-media-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        
        padding: 2.5px;
}
.contact__address-item--social-media-list .contact__social-media-icon {
          color: transparent;
          width: calc(30 * 100vw / 1920);
          height: calc(30 * 100vw / 1920);

          position: relative;
}
.contact__address-item--social-media-list .contact__social-media-icon:not(:first-of-type) {
            margin-left: 17.76%;
}
.contact__address-item--social-media-list .contact__social-media-icon svg {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
}
.contact__address-label {
      color: #E72420;
      color: var(--primary-red);
      margin-bottom: calc(16 * 100vw / 1920);
}
.contact__address-text {
      color: #fff;
}
.contact__address-text-row {
        display: block;
}
.contact__address-text-row:not(:first-of-type) {
          margin-top: calc(16 * 100vw / 1920);
}
.contact__address--tel {
      margin-left: 4.76%;
}
.contact__title {
    font-size: calc(36 * 100vw / 1920);
    font-weight: bold;
    margin-bottom: 21.17%;
    color: #fff;
}
.contact__communication {
    width: calc(420 * 100vw / 1920);
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-top: 2.5%;
    padding-bottom: 2%;
}
.contact__inquiry {
    margin-top: auto;
}
.contact__inquiry-help {
      font-size: calc(16 * 100vw / 1920);
      color: #fff;
      margin-top: calc(36 * 100vw / 1920);
}
.contact__inquiry,
  .contact__recruitment {
    
    width: 100%;
    height: calc(100 * 100vw / 1920);
    

    /* border: 4px solid #fff; */

    background: rgba(123, 123, 123, .3);
    border-radius: 2px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    /* padding: 6%; */

    position: relative;
    overflow: hidden;
}
.contact__inquiry--clip, .contact__recruitment--clip {
      -webkit-clip-path: url(#button-clip-3);
              clip-path: url(#button-clip-3);
}
.contact__inquiry::before, .contact__recruitment::before {
      content: '';
      position: absolute;

      width: calc(840 * 100vw / 1920);
      height: calc(420 * 100vw / 1920);
      
      border-radius: 50%;
      right: 150%;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      z-index: -1;
      -webkit-transition: 2000ms 0ms all cubic-bezier(0.19, 1, 0.22, 1);
      transition: 2000ms 0ms all cubic-bezier(0.19, 1, 0.22, 1);

      background:rgba(123,123,123,1);
      opacity:0.3;
}
.contact__inquiry:hover::before, .contact__recruitment:hover::before {
      -webkit-transform: translateY(-50%) translateX(80%);
              transform: translateY(-50%) translateX(80%);
}
.contact__inquiry-text, .contact__recruitment-text {
      font-size: calc(24 * 100vw / 1920);
      font-weight: bold;
      color: #fff;
      margin-right: auto;
}
.contact__inquiry:hover .contact__inquiry-illus, .contact__recruitment:hover .contact__recruitment-illus {
      -webkit-transform: translateX(25%);
              transform: translateX(25%);
}
.contact__inquiry-illus, .contact__recruitment-illus {
      -webkit-transition: 2000ms 0ms transform cubic-bezier(0.19, 1, 0.22, 1);
      transition: 2000ms 0ms transform cubic-bezier(0.19, 1, 0.22, 1);
      width: calc(29 * 100vw / 1920);
      height: calc(12 * 100vw / 1920);

      display: -webkit-box;

      display: -ms-flexbox;

      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;

      margin-right: 30px;
}
.contact__inquiry-illus > span, .contact__recruitment-illus > span {
        width: 20%;
        height: 100%;
        display: block;

        position: relative;
}
.contact__inquiry-illus > span svg, .contact__recruitment-illus > span svg {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
}
.contact-content {
  position: absolute;
  width: 74.66%;
  top: 27.77%;
  left: 9.27%;
  right: 16%;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
}
.copyright {
  position: absolute;
  bottom: 6.1%;
  left: 11.33%;
  font-size: calc(16 * 100vw / 1920);
  color: #fff;
}
use.contact__inquiry-illus-unit,
use.contact__recruitment-illus-unit {
  fill: #E72420;
  fill: var(--primary-red);
}
.notification {
  visibility: hidden;
  width: calc(292 * 100vw / 1920);
  height: calc(78 * 100vw / 1920);
  background: #f1f1f1;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  padding: calc(30 * 100vw / 1920);

  position: absolute;
  top: calc(30 * 100vw / 1920);
  left: calc(50% - calc(60 * 100vw /1920));
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}
.notification__deco {
    width: calc(36 * 100vw / 1920);   
    height: calc(36 * 100vw / 1920);
    margin-right: calc(16 * 100vw / 1920);

    background: url(/check.png?d0041d69bbf27ecd8ae1e1d3c4a1f8ec) no-repeat center;
    background-size: contain;
}
.notification__text {
    font-size: calc(24 * 100vw / 1920);
    color: #283032;
    color: var(--primary-black);
    font-weight: bold;
}
.contact__address-item--wechat:hover+.qr-wrap {
  opacity: 1;
}
.qr-wrap {
  -webkit-transition: .35s opacity;
  transition: .35s opacity;
  opacity: 0;
  position: relative;
  top: 15px;
  width: 100px;
  
  font-size: 0;
}
.qr-wrap img {
    width: 100%;
}
.contact__recruitment-icon {
  margin-left: 15px;
  width: calc(32 * 100vw / 1920);
  height: calc(36 * 100vw / 1920);
  background: url(/icon-1.svg?248e9c2a8d4d1e2cac296fefd43e1a1f) no-repeat center;
  background-size: contain;
  margin-right: calc(22 * 100vw / 1920);
}
.contact__inquiry-icon {
  margin-left: 15px;
  width: calc(37 * 100vw / 1920);
  height: calc(36 * 100vw / 1920);
  background: url(/icon-2.svg?bf2af5d210d094470cac38c300f1682c) no-repeat center;
  background-size: contain;
  margin-right: calc(21 * 100vw / 1920);
}

:root {
  --primary-red: #E72420;
  --primary-black: #283032;
}
:root {

  /* --dock-width: calc(120 * 100vw / 1920); */
  --dock-width: 6.25vw;
  
  /* --dock-menu-wrap-width: calc(var(--dock-width) * 30 / 120); */

  
  /* --dock-menu-wrap-width: calc(30 * 100vw / 1920); */
  --dock-menu-wrap-width: 1.5625vw;

  /* --dock-logo-wrap-width: calc(var(--dock-width) * 36 / 120); */
  --dock-logo-wrap-width: 1.875vw;
  
  /* --dock-menu-close-size: calc(30 * 100vw / 1920); */
  --dock-menu-close-size: 1.5625vw;

  /* --dock-menu-rect-size: calc(var(--dock-menu-wrap-width) / 2.25); */
  --dock-menu-rect-size: 5.13888vw;
  /* --dock-bullet-item-size: calc(5 * 100vw / 1920); */
  --dock-bullet-item-size: .26041vw;

  /* --menu-width: calc(100vw - var(--dock-width)); */
  --menu-width: 93.75vw;
  /* --menu-column-width: calc(var(--menu-width) / 6); */
  --menu-column-width: 15.625vw;

  /* --scroll-section-width: calc((1920  - 120) * 100vh / 1080); */

  /* --scroll-section-width: calc(100vw - var(--dock-width)); */
  --scroll-section-width: 93.75vw;

/*   
  --logo-ref-width: 258.8;
  --logo-ref-height: 86.26;

  --logo-width: 5rem;
  --logo-height: calc(var(--logo-width) * var(--logo-ref-height) / var(--logo-ref-width));

  --logo-gap: calc(var(--logo-width) * 15.9 / var(--logo-ref-width));

  --logo-frag-one-width: calc(var(--logo-width) * 40.99 / var(--logo-ref-width));
  --logo-frag-two-width: calc(var(--logo-width) * 40.41 / var(--logo-ref-width));
  --logo-frag-three-width: calc(var(--logo-width) * 36.69 / var(--logo-ref-width)); 
  */

  --menu-z-index: 50;
  --dock-z-index: 500;
}
*, 
*::before, 
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
a {
  text-decoration: none;
}
html {
  font-size: 62.5%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif;
  font-size: 1.6rem;
  overflow: hidden;
}
/* * { background-color: rgba(255,0,0,.2); }
* * { background-color: rgba(0,255,0,.2); } 
* * * { background-color: rgba(0,0,255,.2); } 
* * * * { background-color: rgba(255,0,255,.2); } 
* * * * * { background-color: rgba(0,255,255,.2); } 
* * * * * * { background-color: rgba(255,255,0,.2); } 
* * * * * * * { background-color: rgba(255,0,0,.2); } 
* * * * * * * * { background-color: rgba(0,255,0,.2); } 
* * * * * * * * * { background-color: rgba(0,0,255,.2); } */
@font-face {
  font-family: 'Oxanium Regular';
  font-style: normal;
  font-weight: 400;
  src: 
    url(/Oxanium-Regular.woff2?19361c6eab3cf441fe1979cec9011f14) format('woff2'), 
    url(/Oxanium-Regular.woff?74a024abb4ef160db2d87abbf450a998) format('woff'),
    url(/Oxanium-Regular.ttf?f561c6cc1a67e89471db6243ab195c42) format('truetype'),
    url(/Oxanium-Regular.eot?b51bbb68b2deb31742fe95dafe16547d) format('embedded-opentype');
  unicode-range: U+000-5FF; /* Latin glyphs */
}
@font-face {
  font-family: 'Oxanium Medium';
  font-style: normal;
  font-weight: 400;
  src: 
    url(/Oxanium-Medium.woff2?895dffa9e0abf23981bf5c0c07118c1f) format('woff2'), 
    url(/Oxanium-Medium.woff?1eb2fc2c1923cc3701d93c4847358486) format('woff'),
    url(/Oxanium-Medium.ttf?27a1c6e23b1ea753e10b19f56dfc8c1e) format('truetype'),
    url(/Oxanium-Medium.eot?ef43066cd58e0768fdf4aeda16741a8a) format('embedded-opentype');
  unicode-range: U+000-5FF; /* Latin glyphs */
}
#app {
  height: 100%;
}

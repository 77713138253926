
.case-icon {
  width: 100%;
  height: 100%;
}
.pattern {
  width: 100%;
  height: 100%;

  position: relative;
}

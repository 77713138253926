
[data-scroll] {
  padding-left: 6.25vw;
  padding-left: var(--dock-width);
  width: 100%;
  height: 100%;
  position: absolute;
}
[data-scroll-content] {

  position: relative;
  overflow: hidden;

  width: calc(100vw - 6.25vw);

  width: calc(100vw - var(--dock-width));
  height: 100%;
}
.section {

  position: absolute;
  
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  
  display: -webkit-box;
  
  display: -ms-flexbox;
  
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  z-index: -1;
  visibility: hidden;
}
.section-content {
    width: 100%;
    height: 100%;
    position: relative;
}
.section--inited {
    visibility: visible;
    z-index: 20;
}
.dynamic-background-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(/contact-bg.jpg?9eaa0ca27e6bee2a4a81c401b438498f) no-repeat center;
  background-size: cover;

  display: none;
  visibility: hidden;
}
.section-brush {

  position: absolute;
  width: calc(100vw - 6.25vw);
  width: calc(100vw - var(--dock-width));
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
}
.section-brush--0 .section-brush__order-1 {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #fff;
      -webkit-transform-origin: left;
              transform-origin: left;
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
}
.section-brush--1 .section-brush__order-1 {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      background: #E72420;
      background: var(--primary-red);
      -webkit-transform-origin: right;
              transform-origin: right;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
}
.section-brush--1 .section-brush__order-1 .intro-bg {
        visibility: hidden;
        position: absolute;
        height: 100%;
        width: 46.5%;
        top: 0;
        right: 0;
        background: url(/intro-bg.png?12fa88459beeb64cea9aa1556ec8ceda) no-repeat center;
        background-size: cover;
}
.section-brush--1 .section-brush__order-2 {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #fff;
      -webkit-transform-origin: left;
              transform-origin: left;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);

      -webkit-clip-path: url(#arc-right-clip);

              clip-path: url(#arc-right-clip);
}
.section-brush--2 .section-brush__order-1 {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #fff;
      -webkit-transform-origin: left;
              transform-origin: left;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
}
.section-brush--3 .section-brush__order-1 {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      background: #fff;
      -webkit-transform-origin: right;
              transform-origin: right;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
}
.section-brush--4 .section-brush__order-1 {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: right;
              transform-origin: right;
      background: #eee;
}
.section-brush--4 .section-brush__order-1 .about-bg {
        visibility: hidden;
        position: absolute;
        height: 100%;
        width: 60%;
        top: 0;
        right: 0;
        background: url(/about-bg.png?3c739ae5da90e83a168983b8ba8ea2e6) no-repeat center;
        background-size: cover;
}
.section-brush--4 .section-brush__order-2 {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: left;
              transform-origin: left;
      background: #fff;
      transform: scaleX(0);
      -webkit-clip-path: url(#arc-right-clip);
              clip-path: url(#arc-right-clip);
}
.section-brush--5 .section-brush__order-1 {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #2C3132;
      -webkit-transform-origin: left;
              transform-origin: left;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
}
.menu-brush {
  position: absolute;
  width: calc(100vw - 6.25vw);
  width: calc(100vw - var(--dock-width));
  height: 100%;
  top: 0;
  right: 0;
  
  z-index: -1;
}
.menu-brush__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .98);
    -webkit-transform-origin: left;
            transform-origin: left;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
}
.arc {
  position: absolute;
  width: 0;
  height: 0;
}


.menu {

  position: absolute;
  /* z-index: var(--menu-z-index); */
  
  width: 93.75vw;
  
  width: var(--menu-width);
  height: 100%;
  left: 6.25vw;
  left: var(--dock-width);
  top: 0;
  
  z-index: -1;
  
  overflow: hidden;
}
.menu svg {
    width: 0;
    height: 0;
    position: absolute;
}
.menu__row {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.menu__moving-box {

    position: absolute;
    width: 15.625vw;
    width: var(--menu-column-width);
    height: 100vh;
    will-change: transform, opacity;
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
    top: 0;
    background: #E72420;
    background: var(--primary-red);
    -webkit-clip-path: url(#menu-clip-path);
            clip-path: url(#menu-clip-path);
    visibility: hidden;
    opacity: 0;
}
.menu__column {

    cursor: pointer;

    position: relative;

    
    width: 15.625vw;

    
    width: var(--menu-column-width);
    height: 100%;

    -webkit-box-flex: 1;

        -ms-flex: 1 1 15.625vw;

            flex: 1 1 15.625vw;

    -ms-flex: 1 1 var(--menu-column-width);

        flex: 1 1 var(--menu-column-width);

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
  
    visibility: hidden;
}
.menu__column:not(:first-of-type) {
      border-left: solid 1px #F1F1F1;
}
.menu__column-text {

    position: absolute;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    width: 100%;
    min-height: 25.6rem;

    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

    top: 38.14%;

    font-size: calc(48 * 100vw / 1920);
    color: #283032;
    color: var(--primary-black);
    
    font-weight:600;

    -webkit-writing-mode: vertical-rl;

        -ms-writing-mode: tb-rl;

            writing-mode: vertical-rl;
    -webkit-text-orientation: upright;
            text-orientation: upright;
}
.menu__column-text span {
      position: relative;

      letter-spacing: calc(8 * 100vw / 1920);
}
.menu__column-text span::before {
        letter-spacing: 0;
        font-family: 'Oxanium Medium', sans-serif;
        -webkit-writing-mode: horizontal-tb;
            -ms-writing-mode: lr-tb;
                writing-mode: horizontal-tb;
        
        position: absolute;
        content: attr(data-seq-text);
        top: 2.5%;
        right: 110%;
        opacity: .6;
        font-size: calc(16 * 100vw / 1920);
}

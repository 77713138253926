
.preface {
  background: #fff;

  /* background: url('../assets/foo.jpg') no-repeat center / contain; */

  position: absolute;
  width: 100%;
  height: 100%;
}
.slogan {
  position: absolute;
  
  top: 6.11%;
  left: 3.77%;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
}
.slogan__part-1,
  .slogan__part-2 {
    height: 100%;
    width: calc(34 * 100vw / 1920);
    
    display: -webkit-box;
    
    display: -ms-flexbox;
    
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.slogan__part-1 span, .slogan__part-2 span {
      font-size: calc(31 * 100vw / 1920);
      font-weight: bold;
      color: #969A9B;
      line-height: 100%;
}
.slogan__part-1 span:not(:first-of-type), .slogan__part-2 span:not(:first-of-type) {
        margin-top: 1rem;
}
.slogan__part-2 {
    margin-left: 2.1rem;
}
.slogan__deco {
    width: calc(34 * 100vw / 1920);
    height: calc(30 * 100vw / 1920);
    
    display: -webkit-box;
    
    display: -ms-flexbox;
    
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
.slogan__deco svg {
      width: 25%;
}
.brand-heading {
  position: absolute;
  
  left: 3.33%;
  bottom: 16.3%;

  color: #283032;
  font-size: calc(36 * 100vw / 1920);
}
.brand-heading__deco {
    content: '';
    position: absolute;

    width: calc(16 * 100vw / 1920);
    height: calc(39 * 100vw / 1920);

    left: 3.6%;
    bottom: 115%;
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    -webkit-transform: rotate(90deg) translate(calc(-.5 * 39 * 100vw / 1920), 25%);
            transform: rotate(90deg) translate(calc(-.5 * 39 * 100vw / 1920), 25%);
}
.brand-heading__deco svg {
      height: 100%;
}
.scroll-hint {
  /* width: calc(40 * 100vw / 1920);
  height: calc(40 * 100vw / 1920); */
  width: 40px;
  height: 40px;
  
  position: absolute;
  bottom: 5.65%;
  right: 3.66%;
}
.scroll-hint::before {
    content: '';
    position: absolute;
    width: 18px;
    height: 28px;
    border-radius: 2px 2px 9px 9px;
    border: 2px solid rgba(40,48,50,1);

    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.scroll-hint span {
    position: absolute;
    width: 2px;
    height: 5px;
    top: 30%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background: #283032;
    background: var(--primary-black);
    border-radius: 10px;
    -webkit-animation: scroll 1.5s infinite ease-out;
            animation: scroll 1.5s infinite ease-out;
    opacity: 0;
}
@-webkit-keyframes scroll {
10% {
      opacity: 1;
}
70%, 100% {
      -webkit-transform: translateX(-50%) translateY(75%);
              transform: translateX(-50%) translateY(75%);
      opacity: 0;
}
}
@keyframes scroll {
10% {
      opacity: 1;
}
70%, 100% {
      -webkit-transform: translateX(-50%) translateY(75%);
              transform: translateX(-50%) translateY(75%);
      opacity: 0;
}
}
.video-bg-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
}
.video-bg {
  position: absolute;
  right: 0;
  top: 0;
  height: 92.75%;
}
use.slogan__deco-unit,
use.brand-heading__deco-unit {
  fill: #E72420;
  fill: var(--primary-red);
}
use.scroll-hint__arrow-unit {
  fill: var(-primary-black);
}

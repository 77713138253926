
.intro {
  position: absolute;
  width: 100%;
  height: 100%;
}
.goal-wrap {
  position: absolute;
  /* top: 16.94%; */
  top: 12.5%;
  left: 4.44%;
  width: 45.55%;  
  display: -webkit-box;  
  display: -ms-flexbox;  
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.goal {
  width: 43.92%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #283032;
  color: var(--primary-black);
}
.goal__title {
    
    font-size: calc(36 * 100vw / 1920);
    color: #000;
    font-weight: bold;
    margin-bottom: calc(18 * 100vw / 1920);
}
.goal__sub-title {
    line-height: 100%;
    font-size: calc(24 * 100vw / 1920);
    font-weight: bold;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.goal__sub-title > div {
      width: calc(6 * 100vw / 1920);
      height: calc(14 * 100vw / 1920);
      margin-right: calc(12 * 100vw / 1920);

      display: inline-block;
      position: relative;
}
.goal__sub-title > div svg {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: 100%;
}
.goal__description {
    margin-top: calc(74 * 100vw / 1920);
    /* font-size: calc(14 * 100vw / 1920); */
    /* line-height: calc(28 * 100vw / 1920); */
    font-size: calc(16 * 100vw / 1920);
    line-height: calc(30 * 100vw / 1920);
    position: relative;
}
.goal__description::before,
    .goal__description::after {
      position: absolute;
      content: '';
      
      width: calc(24 * 100vw / 1920);
      height: calc(22 * 100vw / 1920);
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAMAAADto6y6AAAANlBMVEVHcEz09PT19fX39/f19fX19fX5+fn19fX19fX29vb19fX19fX19fXv7+/09PT19fX19fX09PT5QUc1AAAAEXRSTlMA8HFGzrkYhONW3iuWCjqsfhiqTkIAAAB/SURBVCjP3Y9hAoUgCIPJUisz2/0vWzik3hXefu2DIShCnXGWoXrFYVMDlgFhAiy1PRbYmInqmcqAQ9kJmgqAQ7K6porZXUPR4Ejuj/rUZ/r1FB/IfVvjbBLfwCtqv85ubXhPLZ+QLB0S/8NtbHB6VQU+O3UQvMrXB34a+Q8bNylSGPe9O/3NAAAAAElFTkSuQmCC') no-repeat center;
      background-size: contain;
}
.goal__description::before {
      left: 0;
      bottom: 110%;
}
.goal__description::after {
      right: 0;
      top: 100%;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
}
.intro-divider {
  width: 45.55%;
  height: 1px;
  background: #e7e7e7;
  position: absolute;
  left: 4.44%;
  /* top: 55.64%; */
  top: 62.5%;
}
.platform {
  position: absolute;
  width: 45.55%;
  /* height: 20.92%; */
  left: 4.44%;
  /* top: 62.4%; */
  top: 68.5%;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
}
.platform::before {
    content: '';
    position: absolute;
    top: -2.5%;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    width: 1px;
    height: calc(241 * 100vw / 1920);
    background: #E7E7E7;
}
.platform__main,
  .platform__product {
    width: 50%;
}
.platform__title {
    font-size: calc(36 * 100vw / 1920);
    font-weight: bold;
}
.platform__enter {
    margin-top: calc(84 * 100vw / 1920);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    /* border: 3px solid var(--primary-black); */
    background: #E72420;
    background: var(--primary-red);
    
    width: calc(296 * 100vw / 1920);
    height: calc(80 * 100vw / 1920);


    position: relative;
    overflow: hidden;
}
/* padding: calc(30 * 100vw / 1920); */
.platform__enter--clip {
      -webkit-clip-path: url(#button-clip);
              clip-path: url(#button-clip);
}
.platform__enter::before {
      content: '';
      position: absolute;

      width: calc(296 * 2 * 100vw / 1920);
      height: calc(296 * 100vw / 1920);
      background: #f1f1f1;
      border-radius: 50%;
      right: 150%;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      z-index: -1;
      -webkit-transition: 2000ms 0ms all cubic-bezier(0.19, 1, 0.22, 1);
      transition: 2000ms 0ms all cubic-bezier(0.19, 1, 0.22, 1);
}
.platform__enter:hover::before {
      -webkit-transform: translateY(-50%) translateX(80%);
              transform: translateY(-50%) translateX(80%);
}
.platform__enter-text {
      font-size: calc(24 * 100vw / 1920);
      /* color: var(--primary-black); */
      color: #fff;
      font-weight: bold;
      margin-right: auto;
      margin-left: 15px;
}
.platform__enter:hover .platform__enter-deco {
      -webkit-transform: translateX(25%);
              transform: translateX(25%);
}
.platform__enter-deco {
      -webkit-transition: 2000ms 0ms transform cubic-bezier(0.19, 1, 0.22, 1);
      transition: 2000ms 0ms transform cubic-bezier(0.19, 1, 0.22, 1);

      display: -webkit-box;

      display: -ms-flexbox;

      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      width: calc(29 * 100vw / 1920);
      height: calc(12 * 100vw / 1920);

      margin-right: 30px;
}
.platform__enter-deco > div {
        width: 20%;
        height: 100%;

        
        display: -webkit-box;

        
        display: -ms-flexbox;

        
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
.platform__enter-deco > div svg {
          height: 100%;
}
.platform__product {
    padding-left: calc(67 * 100vw / 1920);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.platform__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.platform__column {
    width: calc(150 * 100vw / 1920);
    height: calc(85 * 100vw / 1920);

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.platform__column img {
      width: 32.5%;
      height: 58%;
}
.platform__column-text {
      font-size: calc(16 * 100vw / 1920);
      color: #7b7b7b;
      font-weight: bold;
      width: 105%;
      text-align: center;
}
.platform-data {

  width: calc(548 * 100vw / 1920);
  height: calc(70 * 100vw / 1920);
  position: absolute;
  bottom: 16.67%;
  
  right: 5.61%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.platform-data::before {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(60 * 100vw / 1920);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #fff;
}
.platform-data__entity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.platform-data__label {
    font-size: calc(14 * 100vw / 1920);
    color: #fff;
    line-height: 100%;
    margin-bottom: 6.5%;
}
.platform-data__value {
    font-size: calc(36 * 100vw / 1920);
    color: #fff;
    font-family: 'Oxanium Regular', sans-serif;
}
use.goal__sub-title-unit {
  fill: #E72420;
  fill: var(--primary-red);
}
use.platform__enter-deco-unit {
  fill: #fff;
}



.partners {
  position: absolute;
  width: 100%;
  height: 100%;
}
.partner-list {
  position: absolute;
  top: 25%;
  left: 8.28%;
  right: 8.28%;
  bottom: 25%;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
}
.partner-list__ellipsis {
    position: absolute;
    width: calc(8 * 100vw / 1920);
    height: calc(41 * 100vw / 1920);
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: calc(100% + 64 * 100vw / 1920);

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.partner-list__ellipsis span {
      width: calc(8 * 100vw / 1920);
      height: calc(8 * 100vw / 1920);
      border-radius: 50%;
      background: #283032;
      background: var(--primary-black);
}
.partner-list__column {
    width: 20%;
    height: 100%;

    background: #fff;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    -webkit-box-pack: justify;

        -ms-flex-pack: justify;

            justify-content: space-between;

    /* filter: grayscale(100%);

    transition: filter .5s , transform .5s; */

    /* &:hover {
      filter: none;
      transform: scale(1.1);
    } */
}
.partner-list__column:not(:first-of-type) {
      position: relative;
}
.partner-list__column:not(:first-of-type)::before {
        content: '';
        position: absolute;
        height: 59.25%;
        width: 1px;
        left: -.5px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        background: #f1f1f1;
}
.partner-list__item {
    
    -webkit-box-flex: 0;
    
        -ms-flex: 0 0 6.25vw;
    
            flex: 0 0 6.25vw;
    
    width: 100%;
    position: relative;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    -webkit-filter: grayscale(100%);

            filter: grayscale(100%);
}
.partner-list__item > a {
      display: block;
      width: 62.5%;
      height: 100%;
      text-indent: -9999px;
}
/* @for $i from 1 to 20 {
  .partner-#{$i} {
    background: url('../assets/partner-#{$i}.png') no-repeat center;
    background-size: contain;
  }
} */
.partner-1 {
  background: url(/partner-1.png?dd51d9da2e05b0ebf139ef91e2ac943f) no-repeat center;
  background-size: contain;
}
.partner-2 {
  background: url(/partner-2.png?eb81531c0df715238d8c9412290a5037) no-repeat center;
  background-size: contain;
}
.partner-3 {
  background: url(/partner-3.png?27eb490bc4c62d0eeaf8f81754caad2d) no-repeat center;
  background-size: contain;
}
.partner-4 {
  background: url(/partner-4.png?864d6f6f371321267a5f14216c7ec36e) no-repeat center;
  background-size: contain;
}
.partner-5 {
  background: url(/partner-5.png?3a3cddd733d8bd66425868a6909e737c) no-repeat center;
  background-size: contain;
}
.partner-6 {
  background: url(/partner-6.png?2216a1b457098963aa775ef3f69691dc) no-repeat center;
  background-size: contain;
}
.partner-7 {
  background: url(/partner-7.png?7a437679ebc29a4fbefaf74430ad91a0) no-repeat center;
  background-size: contain;
}
.partner-8 {
  background: url(/partner-8.png?e289b40c734784e8d2bc254e49d92828) no-repeat center;
  background-size: contain;
}
.partner-9 {
  background: url(/partner-9.png?48d2d7af51740f234e878f270cd46754) no-repeat center;
  background-size: contain;
}
.partner-10 {
  background: url(/partner-10.png?8628574fbd1e1a6687ed865a645cc05b) no-repeat center;
  background-size: contain;
}
.partner-11 {
  background: url(/partner-11.png?74c7e3b3b2288840b2ca0752f3c32a83) no-repeat center;
  background-size: contain;
}
.partner-12 {
  background: url(/partner-12.png?6cc077b362df9062ee40ad6112eb7419) no-repeat center;
  background-size: contain;
}
.partner-13 {
  background: url(/partner-13.png?305f8343a7d2c752d2f316a29259b5f0) no-repeat center;
  background-size: contain;
}
.partner-14 {
  background: url(/partner-14.png?d4524a7ff11b813ee4e271c300d4ed68) no-repeat center;
  background-size: contain;
}
.partner-15 {
  background: url(/partner-15.png?3664c4556ae1ccc01a0a2c3509d7a7c9) no-repeat center;
  background-size: contain;
}
.partner-16 {
  background: url(/partner-16.png?baeaa2a2fb76a624c6c47543c652c8c6) no-repeat center;
  background-size: contain;
}
.partner-17 {
  background: url(/partner-17.png?97f3d40ccff72f00b0dc3885de48953f) no-repeat center;
  background-size: contain;
}
.partner-18 {
  background: url(/partner-18.png?cfa54cacb8e14dec16bf3ca37b7e1b7b) no-repeat center;
  background-size: contain;
}
.partner-19 {
  background: url(/partner-19.png?f21c68d42f8fed26ef926a30566d929e) no-repeat center;
  background-size: contain;
}
.partner-20 {
  background: url(/partner-20.png?b0a01e51e6b857942f77b3ebc26bf486) no-repeat center;
  background-size: contain;
}

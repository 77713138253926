
.dock {
  position: fixed;
  z-index: 500;
  z-index: var(--dock-z-index);
  height: 100vh;

  width: 6.25vw;

  width: var(--dock-width);
  
  top: 0;
  left: 0;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  border-right: 1px solid rgba(255,255,255, .2);

  background-color: #f5f5f5;
}
.dock__logo {
    /* width ratio 36 / 120 */
    /* top ratio 66 / 1080 */
    /* size ratio 36 / 23 */

    cursor: pointer;


    position: absolute;
    

    width: 1.875vw;
    

    width: var(--dock-logo-wrap-width);
    /* height: calc(23 * var(--dock-logo-wrap-width) / 36); */
    height: 1.19472vw;
    top: calc( 66 * 100vh / 1080);

    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.dock__logo-text {
    -ms-flex-item-align: center;
        align-self: center;
    width: 12%;
}
.dock__menu {
    /* width ratio 40 / 120 */
    /* size ratio 1 / 1 */
    /* bottom ratio 61 / 1080 */

    width: 1.5625vw;

    width: var(--dock-menu-wrap-width);
    height: 1.5625vw;
    height: var(--dock-menu-wrap-width);

    position: absolute;
    display: block;

    bottom: 5.64%;

    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.dock__menu-rect {
    position: absolute;
    
    /* width: var(--dock-menu-rect-size);
    height: var(--dock-menu-rect-size); */
    width: 42.43333%;
    height: 43.9333%;
}
.dock__menu-rect--1 {
      top: 0;
      left: 0;
}
.dock__menu-rect--1 .dock__menu-rect-line {
        position: absolute;
        width: 100%;
        height: 31.03%;
        left: 0;
        background: #283032;
        background: var(--primary-black);
}
.dock__menu-rect--1 .dock__menu-rect-line--1 {
          top: 0;
}
.dock__menu-rect--1 .dock__menu-rect-line--2 {
          bottom: 0;
}
.dock__menu-rect--2 {
      right: 0;
      top: 0;
}
.dock__menu-rect--2 .dock__menu-rect-line {
        position: absolute;
        height: 100%;
        width: 31.03%;
        top: 0;
        background: #283032;
        background: var(--primary-black);
}
.dock__menu-rect--2 .dock__menu-rect-line--1 {
          left: 0;
}
.dock__menu-rect--2 .dock__menu-rect-line--2 {
          right: 0;
}
.dock__menu-rect--3 {
      bottom: 0;
      left: 0;
}
.dock__menu-rect--3 .dock__menu-rect-line {
        position: absolute;
        height: 100%;
        width: 31.03%;
        top: 0;
        background: #283032;
        background: var(--primary-black);
}
.dock__menu-rect--3 .dock__menu-rect-line--1 {
          left: 0;
}
.dock__menu-rect--3 .dock__menu-rect-line--2 {
          right: 0;
}
.dock__menu-rect--4 {
      right: 0;
      bottom: 0;
}
.dock__menu-rect--4 .dock__menu-rect-line {
        position: absolute;
        width: 100%;
        height: 31.03%;
        left: 0;
        background: #283032;
        background: var(--primary-black);
}
.dock__menu-rect--4 .dock__menu-rect-line--1 {
          top: 0;
}
.dock__menu-rect--4 .dock__menu-rect-line--2 {
          bottom: 0;
}
.logo-triple__piece {
    font-size: 0;
    position: absolute;
    height: 100%;
    top: 0;
    width: 28%;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.logo-triple__piece svg {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
}
.logo-triple__piece--left {
      left: 0;
}
.logo-triple__piece--middle {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
}
.logo-triple__piece--right {
      right: 0;
      -webkit-transform: scaleX(-1) translateX(-20%);
              transform: scaleX(-1) translateX(-20%);
}
.logo-triple__piece--left-middle {
      visibility: hidden;
      left: 0;
}
.logo-triple__piece--right-middle {
      visibility: hidden;
      right: 0;
      -webkit-transform: scaleX(-1) translateX(-20%);
              transform: scaleX(-1) translateX(-20%);
}
.bullet-list {

  position: absolute;
  width: calc(16 * 100vw / 1920);
  height: calc(139 * 100vw / 1920);

  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  
  -ms-flex-item-align: center;
  
      align-self: center;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  z-index: -999;
  visibility: hidden;
}
.bullet-list__item {
    
    width: .26041vw;
    
    width: var(--dock-bullet-item-size);
    height: .26041vw;
    height: var(--dock-bullet-item-size);
    background: #e7e7e7;

    cursor: pointer;
}
.bullet-list__current {
    position: absolute;
    width: calc(16 * 100vw / 1920);
    height: calc(40 * 100vw / 1920);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: 5;
    cursor: default;

    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.bullet-list__current span {   
      font-size: calc(16 * 100vw / 1920);
      line-height: 100%;
      font-weight: bold;
      color: #283032;
      color: var(--primary-black);
      margin: 2px 0;
}
.bullet-list__current--1 {
      top: calc(21 * 1 * 100vw / 1920);
}
.bullet-list__current--2 {
      top: calc(21 * 2 * 100vw / 1920);
}
.bullet-list__current--3 {
      top: calc(21 * 3 * 100vw / 1920);
}
.bullet-list__current--4 {
      top: calc(21 * 4 * 100vw / 1920);
}
.bullet-list__current--5 {
      top: calc(21* 5 * 100vw / 1920);
}
use.logo-triple__left,
use.logo-triple__middle,
use.logo-triple__right,
use.logo-triple__left-middle,
use.logo-triple__right-middle {
  fill: #E72420;
  fill: var(--primary-red);
}
.case-detail-bullet-list,
.recruitment-bullet-list {
  width: calc(18 * 100vw / 1920);
  height: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  visibility: hidden;
  z-index: -100;
}
.case-detail-bullet-list__item, .recruitment-bullet-list__item {
    cursor: pointer;
    width: calc(18 * 100vw / 1920);
    height: calc(18 * 100vw / 1920);
    margin-top: calc(18 * 100vw / 1920);
    margin-bottom: calc(18 * 100vw / 1920);

    position: relative;
}
.case-detail-bullet-list__item--active .case-detail-bullet-list__outer,.case-detail-bullet-list__item--active .recruitment-bullet-list__outer,.recruitment-bullet-list__item--active .case-detail-bullet-list__outer,.recruitment-bullet-list__item--active .recruitment-bullet-list__outer {
      display: block;
}
.case-detail-bullet-list__outer, .recruitment-bullet-list__outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;

    display: none;
}
.case-detail-bullet-list__inner, .recruitment-bullet-list__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(7 * 100vw / 1920);
    height: calc(7 * 100vw / 1920);
    background: #fff;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}


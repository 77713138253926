
.about {
  
  position: absolute;
  
  width: 93.75vw;
  
  width: var(--menu-width);
  height: 100%;
  left: 6.25vw;
  left: var(--dock-width);
  top: 0;
}

  /* z-index: -1;
  display: none; */
.about__brush {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.about__brush-order-1 {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: right;
              transform-origin: right;
      background: #eee;
}
.about__brush-order-2 {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transform-origin: left;
              transform-origin: left;
      background: #fff;
      transform: scaleX(0); 
      /* .5055 */
}
.about__title {
    font-size: calc(64 * 100vw / 1920);
    font-weight: bold;
    color: #000;
    /* margin-bottom: calc(97 * 100vw / 1920); */
    margin-bottom: 20%;
    position: relative;
    line-height: 100%;
}
.about__description {
    font-size: calc(24 * 100vw / 1920);
    font-weight: bold;
    color: #000;
    line-height: calc(36 * 100vw / 1920);
    position: relative;
}
.about__description-deco {
      width: calc(18 * 100vw / 1920);
      height: calc(9 * 100vw / 1920);
      position: absolute;
      bottom: calc(100% + calc(18 * 100vw / 1920));
      left: 0;
}
.about__description-deco > div {
        width: calc(9 * 100vw / 1920);
        height: calc(18 * 100vw / 1920);
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(90deg);
                transform: translate(-50%, -50%) rotate(90deg);
}
.about__description-deco > div svg {
          width: 100%;
          
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
}
.about__text {
    /* margin-top: calc(48 * 100vw / 1920); */
    margin-top: 5%;
    font-size: calc(16 * 100vw / 1920);
    color: #283032;
    color: var(--primary-black);
    line-height: calc(30 * 100vw / 1920);
    border-top: 1px solid #e7e7e7;
    padding-top: calc(24 * 100vw / 1920)
}
.about__go-back-wrap {
    position: absolute;
    top: 6.48%;
    left: 10.44%;
}
.about-content {
  position: absolute;
  width: 24.88%;
  top: 17.59%;
  /* left: 10%; */
  left: 4.5%;
}
.platform__enter-2--clip {
      -webkit-clip-path: url(#button-clip-2);
              clip-path: url(#button-clip-2);
}
use.about__description-deco-unit {
  fill: #E72420;
  fill: var(--primary-red);
}


